<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      :loading="loading"
    >
      <v-toolbar
        dense
        color="transparent"
      >
        <v-btn
          icon
          @click="$router.push({name: 'priceList'})"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="viewRow"
          v-permission="['70003']"
          color="primary"
          class="mx-2"
          small
          @click="editClick"
        >
          编辑
        </v-btn>
        <v-btn
          v-else
          small
          color="primary"
          class="mx-2"
          @click="formSubmit"
        >
          保存
        </v-btn>
        <v-btn
          outlined
          small
          class="mx-2"
          @click="$router.push({name: 'priceList'})"
        >
          取消
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 85vh"
      >
        <v-form
          ref="priceListFormRef"
          :disabled="viewRow"
        >
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 基本信息 ---
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.name"
                :counter="20"
                :rules="[rules.required, rules.counter]"
                label="价目表名称"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 价格列表 ---
          </v-card-subtitle>
          <v-data-table
            :headers="itemsHeaders"
            :items="items"
            :items-per-page.sync="itemsOptions.pageSize"
            :page.sync="itemsOptions.page"
            :server-items-length="itemTotal"
            :loading="itemsLoading"
            :disable-sort="true"
            :expanded.sync="expanded"
            item-key="sku"
            :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
            show-expand
            @update:items-per-page="getItems"
            @update:page="getItems"
          >
            <template v-slot:top>
              <v-text-field
                v-model="itemsOptions.search"
                dense
                outlined
                label="搜索"
                placeholder="按产品名称搜索"
                :append-icon="icons.mdiMagnify"
                @click:append="getItems"
                @keyup.enter.native="getItems"
              />
            </template>
            <template v-slot:item.name="{ item }">
              <router-link
                tag="a"
                target="_blank"
                :to="{name:'goodsDetail', query:{id: item.id}}"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:item.new_price="{ item }">
              <v-text-field
                v-model="item.new_price"
                :rules="[rules.required, rules.amount]"
                hide-details="auto"
                dense
                @change="updateItems[item.sku] = item"
              ></v-text-field>
            </template>
            <template v-slot:item.is_show="{ item }">
              <v-simple-checkbox
                v-model="item.is_show"
                :ripple="false"
                color="primary"
                :disabled="viewRow"
                @input="goodsIsShowClick(item)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="variantHeaders"
                  :items="item.variants"
                  disable-sort
                  disable-pagination
                  hide-default-footer
                  item-key="sku"
                >
                  <template v-slot:item.new_price="{ item: v_item }">
                    <v-text-field
                      v-model="v_item.new_price"
                      :rules="[rules.required, rules.amount]"
                      hide-details="auto"
                      dense
                      @change="updateItems[item.sku] = item"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.is_show="{ item: v_item }">
                    <v-simple-checkbox
                      v-model="v_item.is_show"
                      :ripple="false"
                      color="primary"
                      disabled
                      @input="updateItems[item.sku] = item"
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiDelete,
  mdiArrowLeft,
  mdiMagnify,
  mdiPlus,
} from '@mdi/js'

import {
  getPriceList,
  getPriceListItems,
  updatePriceList,
} from '@/api/goods'

export default {
  name: 'PriceListDetail',
  data() {
    return {
      rowId: null,
      viewRow: true,
      loading: false,
      row: {
        name: null,
      },
      updateItems: {},
      items: [],
      expanded: [],
      rules: {
        required: value => (!!value || value === 0) || '必填项.',
        counter: value => (!value || value.length <= 20) || '最大20个字符',
        integer: value => (!value || /^\d*$/.test(value)) || '必须为正整数',
        amount: value => (!value || /^\d+(\.\d{1,2})?$/.test(value)) || '价格不合法',
      },
      icons: {
        mdiDelete,
        mdiArrowLeft,
        mdiMagnify,
        mdiPlus,
      },
      itemsHeaders: [
        { text: '产品名称', value: 'name' },
        { text: 'SKU', value: 'sku' },
        { text: '成本价', value: 'cost_price' },
        { text: '售价', value: 'sale_price' },
        { text: '当前价', value: 'new_price' },
        { text: '前台可见', value: 'is_show' },
      ],
      variantHeaders: [
        { text: '变体', value: 'name' },
        { text: 'SKU', value: 'sku' },
        { text: '成本价', value: 'cost_price' },
        { text: '售价', value: 'sale_price' },
        { text: '当前价', value: 'new_price' },
        { text: '前台可见', value: 'is_show' },
      ],
      itemsOptions: {
        search: '',
        page: 1,
        pageSize: 10,
      },
      itemTotal: 0,
      itemsLoading: false,
    }
  },
  created() {
    const { id } = this.$route.query
    if (id) {
      this.rowId = id
      this.getPriceList()
      this.getItems()
    }
  },
  methods: {
    getPriceList() {
      this.loading = true
      getPriceList(this.rowId).then(response => {
        this.row = response.data
        this.loading = false
      })
    },
    getItems() {
      this.itemsLoading = true
      getPriceListItems(this.rowId, this.itemsOptions).then(response => {
        this.items = response.data.results
        this.itemTotal = response.data.count
        this.itemsLoading = false
      })
    },
    goodsIsShowClick(item) {
      if (item.is_show) {
        item.variants.forEach(v => {
          v.is_show = true
        })
      } else {
        item.variants.forEach(v => {
          v.is_show = false
        })
      }
      this.updateItems[item.sku] = item
    },
    editClick() {
      this.viewRow = false
    },
    formSubmit() {
      if (!this.$refs.priceListFormRef.validate()) return
      const items = []
      Object.keys(this.updateItems).forEach(sku => {
        const item = this.updateItems[sku]
        const variants = []
        if (item.variants) {
          item.variants.forEach(v => {
            variants.push({
              variant_id: v.id,
              sale_price: v.new_price,
              is_show: v.is_show,
            })
          })
        }
        items.push({
          goods_id: item.id,
          sale_price: item.new_price,
          is_show: item.is_show,
          variants,
        })
      })
      this.row.items = items
      updatePriceList(this.rowId, this.row).then(res => {
        this.$toast.success('价目表更新成功')
        this.$router.go(0)
      })
    },
  },
}
</script>

<style scoped>

</style>
